import reactLogo from "../images/react.png";
import gatsbyLogo from "../images/gatsby.png";
import nextLogo from "../images/next.png";
import htmlLogo from "../images/html5.png";
import javaScriptLogo from "../images/js.png";
import typeScriptLogo from "../images/ts.png";
import cssLogo from "../images/css3.png";
import postgresqlLogo from "../images/postgresql.webp";
import figmaLogo from "../images/figmaLogo.webp";
import photoshopLogo from "../images/photoshop.svg";
import githubLogo from "../images/github.png";
import bitBucketLogo from "../images/bitBucketLogo.png";
import vscodeLogo from "../images/vscode.png";
import leagueLogo from "../images/league.png";
import graphqlLogo from "../images/graphql.png";
import sassLogo from "../images/sassLogo.png";
import motionLogo from "../images/motionLogo.webp";

export const skills = [
  {
    title: "React",
    link: "https://reactjs.org/",
    color: "#31AFD4",
    img: reactLogo,
    alt: "react framework",
  },

  {
    title: "Next",
    link: "https://nextjs.org/",
    color: "#000000",
    img: nextLogo,
    alt: "next framework",
  },
  {
    title: "GraphQL",
    link: "https://graphql.org/",
    color: "#E10098",
    img: graphqlLogo,
    alt: "GraphQL Language",
  },
  {
    title: "CSS",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    color: "#264DE4",
    img: cssLogo,
    alt: "css Language",
  },
  {
    title: "Gatsby",
    link: "https://www.gatsbyjs.com/",
    color: "#663399",
    img: gatsbyLogo,
    alt: "gatsby framework",
  },
  {
    title: "Html 5",
    link: "https://developer.mozilla.org/en-US/docs/Learn/Getting_started_with_the_web/HTML_basics",
    color: "#E44D26",
    img: htmlLogo,
    alt: "Html Language",
  },
  {
    title: "javaScript",
    link: "https://www.javascript.com/",
    color: "#FDD83C",
    img: javaScriptLogo,
    alt: "javaScript Language",
  },
  {
    title: "TypeScript",
    link: "https://www.typescriptlang.org/",
    color: "#3178C6",
    img: typeScriptLogo,
    alt: "TypeScript Language",
  },
  {
    title: "CSS",
    link: "https://developer.mozilla.org/en-US/docs/Web/CSS",
    color: "#264DE4",
    img: cssLogo,
    alt: "css Language",
  },
  {
    title: "Sass",
    link: "https://sass-lang.com/",
    color: "#CC6699",
    img: sassLogo,
    alt: "Sass Language",
  },
  {
    title: "Framer Motion",
    link: "https://www.framer.com/motion/",
    color: "#6e2fb6",
    img: motionLogo,
    alt: "Framer Motion Library",
  },
  {
    title: "Postgresql",
    link: "https://www.postgresql.org/",
    color: "#3178C6",
    img: postgresqlLogo,
    alt: "postgresql Language",
  },
  {
    title: "GraphQL",
    link: "https://graphql.org/",
    color: "#E10098",
    img: graphqlLogo,
    alt: "GraphQL Language",
  },
  {
    title: "Figma",
    link: "https://www.figma.com/",
    color: "#333432",
    img: figmaLogo,
    alt: "Figma Program",
  },
  {
    title: "Photoshop",
    link: "https://www.adobe.com/products/photoshop.html",
    color: "#4FCCFE",
    img: photoshopLogo,
    alt: "photoshop Program",
  },
  {
    title: "github",
    link: "https://github.com/",
    color: "#50514F",
    img: githubLogo,
    alt: "github Program",
  },
  {
    title: "BitBucket",
    link: "https://bitBucket.com/",
    color: "#333432",
    img: bitBucketLogo,
    alt: "bitBucket Program",
  },
  {
    title: "VsCode",
    link: "https://code.visualstudio.com/",
    color: "#31AFD4",
    img: vscodeLogo,
    alt: "vscode Program",
  },
  {
    title: "League",
    link: "https://www.leagueoflegends.com/en-us/",
    color: "#31AFD4",
    img: leagueLogo,
    alt: "league Program",
  },
];
const smarty = [
  "Html 5",
  "Next",
  "javaScript",
  "CSS",
  "BitBucket",
  "VsCode",
  "Postgresql",
];
const SmartRent = [
  "Gatsby",
  "TypeScript",
  "Framer Motion",
  "GraphQL",
  "Figma",
  "github",
];
const Blabu = [
  "React",
  "Figma",
  "javaScript",
  "Sass",
  "Framer Motion",
  "Photoshop",
  "github",
  "Html 5",
];

export const SmartySkills = skills.filter((skill) =>
  smarty.includes(skill.title)
).sort((a, b) => smarty.indexOf(a.title) - smarty.indexOf(b.title));
export const SmartRentSkills = skills.filter((skill) =>
  SmartRent.includes(skill.title)
).sort((a, b) => SmartRent.indexOf(a.title) - SmartRent.indexOf(b.title));
export const BlabuSkills = skills.filter((skill) =>
  Blabu.includes(skill.title)
).sort((a, b) => Blabu.indexOf(a.title) - Blabu.indexOf(b.title));
