import React from "react";
import { motion } from "framer-motion";

const ContactText = () => {
  return (
    <h2 id="contact">
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        C
      </motion.span>
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        O
      </motion.span>
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        N
      </motion.span>
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        T
      </motion.span>
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        A
      </motion.span>
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        C
      </motion.span>
      <motion.span
        style={{
          textShadow: "0 0 30px #6e2fb6",
          transition: "color 0.5s", // Transition for color change
        }}
        animate={{
          textShadow: [
            "0 0 30px #ff0000", // Red
            "0 0 30px #ff7f00", // Orange
            "0 0 30px #ffff00", // Yellow
            "0 0 30px #00ff00", // Green
            "0 0 30px #0000ff", // Blue
            "0 0 30px #4b0082", // Indigo
            "0 0 30px #9400d3", // Violet
            "0 0 30px #ff0000", // Red
          ],
        }}
        transition={{ duration: 4, repeat: Infinity }}
      >
        T
      </motion.span>
    </h2>
  );
};

export default ContactText;
